import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAt8pRUSTWqVFepn7aeMURplTgtTxbtNTc",
  authDomain: "coinmarketg.firebaseapp.com",
  databaseURL: "https://coinmarketg.firebaseio.com",
  projectId: "coinmarketg",
  storageBucket: "coinmarketg.appspot.com",
  messagingSenderId: "563904081896",
  appId: "1:563904081896:web:93dcad098abf4a582e7e5d"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);