<template>
  <v-app>
    <v-app-bar app color="primary">
      <v-toolbar-title>CoinMarketG</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon="mdi-logout" v-if="isAuthenticated" @click="logout"></v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { auth } from './firebase';
import { onAuthStateChanged, signOut} from 'firebase/auth';
export default defineComponent({
  name: 'App',
  setup() {
    const router = useRouter();
    const isAuthenticated = ref(false);

    function logout() {
      signOut(auth);
    }

    onAuthStateChanged(auth, async user => {
      if (user) {
        isAuthenticated.value = true;
        router.push({ name: 'Home' });
      } else {
        router.push({ name: 'Login' });
      }
    });

    return {
      logout,
      isAuthenticated
    }
  },
});
</script>
<style scoped>
.v-container{padding: 0 !important;}
</style>
