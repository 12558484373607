import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://api.coingecko.com/api/v3/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  async searchCryptocurrency(query) {
    try {
      const response = await apiClient.get(`/search?query=${query}`);
      return response.data;
    } catch (error) {
      console.error(`Erreur lors de la recherche de la cryptomonnaie : ${error}`);
      return null;
    }
  },
  async getCryptocurrencyData(ids) {
    try {
      const response = await apiClient.get(`/coins/markets?vs_currency=usd&ids=${ids}&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=24h`);
      
      // Récupération du taux de change USD/EUR et USD/ETH
      const exchangeRates = await apiClient.get('/exchange_rates');
      const btcToUsd = exchangeRates.data.rates.usd.value;
      const usdToEur = exchangeRates.data.rates.eur.value;
      const usdToEth = exchangeRates.data.rates.eth.value;
      // Conversion des prix en USD en prix en EUR et ETH
      const cryptocurrencyData = response.data.map(crypto => {
        return {
          ...crypto,
          price_eur: crypto.current_price / (btcToUsd / usdToEur),
          price_eth: crypto.current_price / (btcToUsd / usdToEth),
        }
      });

      return cryptocurrencyData;
    } catch (error) {
      console.error(`Erreur lors de la récupération des données de la cryptomonnaie : ${error}`);
      return null;
    }
  },
};
