<template>
  <v-app>
    <v-main>
      <v-container>
        <v-card max-width="400" class="mx-auto" prepend-icon="mdi-home" :title="title">
          <v-card-text>
            <v-form @submit.prevent="onSubmit">
              <v-text-field v-model="email" label="Email" type="email" required></v-text-field>
              <v-text-field v-model="password" label="Mot de passe" type="password" required></v-text-field>
              <v-btn color="primary" type="submit">Se connecter</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'CoinLogin',
  setup() {
    const router = useRouter();
    const title = 'Connexion';
    const email = ref('');
    const password = ref('');

    const auth = getAuth();

    function onSubmit() {
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then(() => {
          router.push({ name: 'Home' });
        })
        .catch(error => {
          console.error(error);
        });
    }

    return {
      title,
      email,
      password,
      onSubmit,
    };
  },
});
</script>