<template>
  <v-dialog v-model="dialogVisible" max-width="600">
    <v-card>
      <v-card-title>Ajouter une cryptomonnaie</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selectedCoin"
          v-model:search="searchCoinText"
          :items="cryptocurrencyOptions"
          variant="outlined"
          item-title="name"
          return-object
          label="Cryptocurrencies"
          clearable
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :prepend-avatar="item?.raw?.large"
              :title="item?.raw?.name"
              :subtitle="item?.raw?.symbol"
            ></v-list-item>
          </template>
        </v-autocomplete>
        <v-text-field
          v-model.number="quantity"
          variant="outlined"
          label="Quantité"
          clearable
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey-darken-1" text @click="dialogVisible = !dialogVisible">Annuler</v-btn>
        <v-btn color="green-darken-1" text @click="addCoin">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from 'vue';
import coingecko from '@/services/coingecko';
import { db } from '@/firebase';
import { ref as dbRef, set, push } from 'firebase/database';

export default {
  name: 'addCoin',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:value'],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('update:close', newValue);
      },
    },
  },
  setup(props, { emit }) {
    const selectedCoin = ref(null);
    const searchCoinText = ref('');
    const quantity = ref(null);
    const cryptocurrencyOptions = ref([]);
    const dialogVisible = ref(props.value);

    async function fetchCryptocurrencies(query) {
      const data = await coingecko.searchCryptocurrency(query);
      if (data) {
        cryptocurrencyOptions.value = data.coins;
      }
    }

    watch(searchCoinText, async (newTxt) => {
      if (newTxt.length % 3 === 1 && newTxt.length > 1) {
        await fetchCryptocurrencies(newTxt)
      }
    });

    function onCoinSelected(coin) {
      console.log(coin)
      // coinDatas.value = {
      //   id: coin.id,
      //   name: coin.name,
      //   symbol: coin.symbol,
      //   logo: coin.large
      // };
    }

    async function addCoin() {
      if (selectedCoin.value && quantity.value > 0) {
        let portfolioDatas = {
          id: selectedCoin.value.id,
          name: selectedCoin.value.name,
          symbol: selectedCoin.value.symbol,
          logo: selectedCoin.value.large,
          quantity: quantity.value
        };
        const newPortfolio = push(dbRef(db, 'portfolio'), portfolioDatas);
        await set(newPortfolio, portfolioDatas);

        // Fermer la boîte de dialogue
        dialogVisible.value = false;
        emit('update:save', portfolioDatas);

        // Réinitialiser les valeurs
        selectedCoin.value = null;
        quantity.value = null;
      }
    }

    return {
      selectedCoin,
      searchCoinText,
      quantity,
      cryptocurrencyOptions,
      fetchCryptocurrencies,
      onCoinSelected,
      addCoin,
    };
  },
};
</script>
