import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const store = defineStore('cacheStore', {
  state: () => {
    return {
      cache: useStorage('cache', {values: {}, timestamp: {}}),
    };
  },
  actions: {
    setCache(cache) {
      this.cache = cache;
    },
  },
  getters: {
    getCache: (state) => {
      const cacheItem = state.cache;
      if (!cacheItem) {
        return null;
      }

      const timestampDiff = (Date.now() - cacheItem.timestamp) / 1000 / 60;
      if (timestampDiff > 10) {
        state.cache = {values: {}, timestamp: {}};
        return null;
      }

      return cacheItem;
    },
  },
})

export default store;