<template>
    <v-data-table
      class="h-auto"
      v-model:sort-by="sortBy"
      :fixed-header="true"
      v-model:items-per-page="itemsPerPage"
      :headers="headers"
      :items="coins"
      item-value="name"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:top>
        <v-toolbar row
        >
          <v-toolbar-title style="flex:inherit">{{ totalPortfolio }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-select class="mt-6 d-none d-lg-block"
            label="Conversion"
            variant="outlined"
            style="max-width:150px;"
            density="compact"
            v-model="currency"
            :items="['usd', 'eur', 'eth']"
          ></v-select>
          <v-divider
            class="ml-4 mr-1 d-none d-lg-block"
            inset
            vertical
          ></v-divider>
          <v-btn icon="mdi-refresh" variant="flat" @click="fetchPortfolioValue" small></v-btn>
          <v-spacer></v-spacer>
          <v-btn icon="mdi-cash-plus" @click="dialogNew = !dialogNew" class="ms-5"></v-btn>
        </v-toolbar>
        <v-select class="mt-6 xs12 px-3 d-lg-none"
          label="Conversion"
          variant="outlined"
          density="compact"
          v-model="currency"
          :items="['usd', 'eur', 'eth']"
        ></v-select>
      </template>
      <template #headers v-if="mobile">
      </template>
      <template #item="{ item }">
        <tr :class="mobile ? 'v-data-table__mobile-table-row' : ''">
          <td v-if="!mobile">{{ item.raw.rank }}</td>
          <td v-else class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__header">#</div>
            <div class="v-data-table__mobile-row__cell">{{ item.raw.rank }}</div>
          </td>
          <td v-if="!mobile">
            <v-list>
              <v-list-item
                :prepend-avatar="item?.raw?.logo"
                :title="item.raw.name"
                :subtitle="item?.raw?.symbol?.toUpperCase()"
              >
              </v-list-item>
            </v-list>
          </td>
          <td v-else class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__header">Nom</div>
            <div class="v-data-table__mobile-row__cell">
              <v-list>
                <v-list-item
                  :prepend-avatar="item?.raw?.logo"
                  :title="item.raw.name"
                  :subtitle="item?.raw?.symbol?.toUpperCase()"
                >
                </v-list-item>
              </v-list>
            </div>
          </td>
          <td v-if="!mobile">{{ item.raw.quantity }}</td>
          <td v-else class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__header">Qty</div>
            <div class="v-data-table__mobile-row__cell">{{ item.raw.quantity }}</div>
          </td>
          <td v-if="!mobile">
            {{ item.raw['price_' + currency] > 1 ? formatters[currency].format(item.raw['price_' + currency]) : formatters[currency + '_0'].format(item.raw['price_' + currency]) }}
          </td>
          <td v-else class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__header">Price</div>
            <div class="v-data-table__mobile-row__cell">
              {{ item.raw['price_' + currency] > 1 ? formatters[currency].format(item.raw['price_' + currency]) : formatters[currency + '_0'].format(item.raw['price_' + currency]) }}
            </div>
          </td>
          <td v-if="!mobile"><p :class="item.raw.change_24h > 0 ? 'text-green' : 'text-red'">{{ Math.round((item.raw.change_24h + Number.EPSILON) * 100) / 100 }} %</p></td>
          <td v-else class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__header">Evolution (24h)</div>
            <div class="v-data-table__mobile-row__cell">
              <p :class="item.raw.change_24h > 0 ? 'text-green' : 'text-red'">{{ Math.round((item.raw.change_24h + Number.EPSILON) * 100) / 100 }} %</p>
            </div>
          </td>
          <td v-if="!mobile">{{ formatters[currency].format(item.raw['price_' + currency] * item.raw.quantity) }}</td>
          <td v-else class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__header">Valeur</div>
            <div class="v-data-table__mobile-row__cell">
              {{ formatters[currency].format(item.raw['price_' + currency] * item.raw.quantity) }}
            </div>
          </td>
          <td v-if="!mobile" style="text-align: right;">
            <v-btn icon="mdi-pencil" @click="editCrypto(item)" class="me-2" variant="flat"></v-btn>
            <v-btn icon="mdi-delete" class="text-red" variant="flat" @click="deleteCrypto(item)"></v-btn>
            <!-- <add-coin></add-coin> -->
          </td>
        </tr>
      </template>
      <!-- <template #[`item.name`]="{ item }">
        <v-list>
          <v-list-item
            :prepend-avatar="item?.raw?.logo"
            :title="item.raw.name"
            :subtitle="item?.raw?.symbol?.toUpperCase()"
          >
          </v-list-item>
        </v-list>
      </template> -->
      <!-- <template #[`item.price`]="{ item }">
        {{ formatters[currency].format(item.raw['price_' + currency]) }}
      </template>
      <template #[`item.change_24h`]="{ item }">
        <p :class="item.raw.change_24h > 0 ? 'text-green' : 'text-red'">{{ item.raw.change_24h }} %</p>
      </template>
      <template #[`item.total`]="{ item }">
        {{ formatters[currency].format(item.raw['price_' + currency] * item.raw.quantity) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn icon="mdi-pencil" class="me-2" variant="flat"></v-btn>
        <v-btn icon="mdi-delete" class="text-red" variant="flat"></v-btn>
        <add-coin></add-coin>
      </template> -->
    </v-data-table>
    <edit-coin :value="dialogEdit" :coin="dialogEditCoin" @update:value="dialogEdit = $event" @update:coin="changeCoin"></edit-coin>
    <add-coin :value="dialogNew" @update:close="dialogNew = $event" @update:save="addCoin($event)"></add-coin>
    <v-row justify="center">
      <v-dialog
        v-model="dialogDelete"
        persistent
        width="auto"
      >
        <v-card>
          <v-card-title class="text-h5">
            Supprimer {{ delCrypto.name }}
          </v-card-title>
          <v-card-text>Êtes-vous sûr de vouloir supprimer cette cryptomonnaie ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              variant="text"
              @click="dialogDelete = !dialogDelete, delCrypto = {}"
            >
              Annuler
            </v-btn>
            <v-btn
              color="red-darken-1"
              variant="text"
              @click="confirmDeleteCrypto()"
            >
              Supprimer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
import { ref, onMounted, watch, computed, nextTick } from 'vue';
import store from '@/store'
import coingecko from '@/services/coingecko';
import { db } from '@/firebase';
import { ref as dbRef, get, onValue, remove } from 'firebase/database';
import editCoin from '@/components/editCoin';
import addCoin from '@/components/addCoin';
import { useDisplay } from 'vuetify'

export default {
  name: 'CoinPortfolio',
  components: {editCoin, addCoin},
  setup() {
    const delCrypto = ref({});
    const dialogDelete = ref(false);
    const dialogNew = ref(false);
    const sortBy = ref([{ key: 'rank', order: 'asc' }]);
    const formatters = {
      usd: new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'USD',
      }),
      usd_0: new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 5
      }),
      eur: new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
      }),
      eur_0: new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 5
      }),
      eth: {
        format (amount) {
          const options = { style: 'currency', currency: 'ETH', minimumFractionDigits: 8 };
          const numberFormat = new Intl.NumberFormat('fr-FR', options);
          const parts = numberFormat.formatToParts(amount)

          return parts.reduce((acc, part) => {
            switch (part.type) {
              case 'currency':
                // do whatever you need with the symbol. 
                // here I just replace it with the value from the map
                return acc + 'Ξ'
              default:
                return acc + part.value
            }
          }, '') 
        }
      }
    }
    const totalPortfolio = computed(() => {
      let total = 0
      coins.value.map(item => total += item['price_' + currency.value] * item.quantity);
      return formatters[currency.value].format(total);
    });
    const currency = ref('eur');
    const title = ref('Portfolio de cryptomonnaie');
    const cacheStore = store();
    const itemsPerPage = 20;
    const searchCoinText = ref('');
    const quantity = ref(0);
    const dialogEdit = ref(false);
    const dialogEditCoin = ref({});
    const coinDatas = ref({});
    var coins = ref([]);
    const headers = [
      { title: '#', key: 'rank', width: '15'},
      {
        title: 'Nom',
        align: 'start',
        sortable: false,
        key: 'name',
      },
      { title: 'Qty', key: 'quantity', width: '20'},
      { title: 'Price', key: 'price' },
      { title: 'Evolution (24h)', key: 'change_24h' },
      { title: 'Valeur', key: 'total' },
      { title: '', key: 'actions', sortable: false, align: 'end' },
    ];
    const { mobile } = useDisplay();

    const searchPlaceholder = 'Rechercher une cryptomonnaie';

    watch(searchCoinText, async (newTxt) => {
      await fetchCryptocurrencies(newTxt)
    });

    onMounted(async () => {
      const portfolioRef = dbRef(db, 'portfolio');
      const snapshot = await get(portfolioRef);
      onValue(portfolioRef, snapshot => {
        updatePorfolio(snapshot.val());
      });
    });

    async function fetchPortfolioValue() {
      const portfolioRef = dbRef(db, 'portfolio');
      const snapshot = await get(portfolioRef);
      const json = snapshot.val();
      updatePorfolio(json, true);
    }

    async function updatePorfolio(json, noCache = false) {
      let cache = cacheStore.getCache;
      if (!noCache && cache && Date.now() - cache.timestamp < 600000) {
        coins.value = Object.entries(json).map(([key, value]) => {
          let line = cache.values.find(x => x.id === value.id);            
          return {
            key,
            ...value,
            ...line
          }
        });
      } else {
        coingecko.getCryptocurrencyData(Object.values(json).map(val => {return val.id}).toString()).then((datas) => {
          coins.value = Object.entries(json).map(([key, value]) => {
            let line = datas.find(x => x.id === value.id);
            return {
              key,
              ...value,
              price_usd: line.current_price,
              price_eur: line.price_eur,
              price_eth: line.price_eth,
              change_24h: line.price_change_percentage_24h,
              total: line.current_price * value.quantity,
              rank: line.market_cap_rank
            }
          });

          let cryptocurrencyData = Object.entries(json).map(([key, value]) => {
            let line = datas.find(x => x.id === value.id);
            return {
              key,
              id: value.id,
              price_usd: line.current_price,
              price_eur: line.price_eur,
              price_eth: line.price_eth,
              change_24h: line.price_change_percentage_24h,
              total: line.current_price * value.quantity,
              rank: line.market_cap_rank
            }
          });
          cacheStore.setCache({ values: cryptocurrencyData, timestamp: Date.now() });
        });
      }
    }

    async function editCrypto(item) {
      dialogEditCoin.value = Object.assign(ref({}), item.raw);
      await nextTick()
      dialogEdit.value = !dialogEdit.value
    }

    function deleteCrypto(crypto) {
      delCrypto.value = crypto.raw;
      dialogDelete.value = !dialogDelete.value;
    }

    async function confirmDeleteCrypto() {
      console.log(delCrypto.value);
      const portfolioRef = dbRef(db, 'portfolio/' + delCrypto.value.key);
      await remove(portfolioRef);
      delCrypto.value = {};
      dialogDelete.value = !dialogDelete.value;
    }

    function changeCoin(evt) {
      console.log(evt);
    }

    function addCoin(evt) {
      dialogNew.value = !dialogNew.value;
    }

    return {
      sortBy,
      dialogDelete,
      delCrypto,
      formatters,
      mobile,
      totalPortfolio,
      currency,
      coins,
      itemsPerPage,
      headers,
      title,
      searchCoinText,
      quantity,
      searchPlaceholder,
      coinDatas,
      dialogEdit,
      dialogNew,
      dialogEditCoin,
      fetchPortfolioValue,
      editCrypto,
      changeCoin,
      deleteCrypto,
      confirmDeleteCrypto,
      addCoin
    };
  },
};
</script>
<style scoped>
.v-table>.v-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child {
  border-bottom: thin solid hsla(0,0%,100%,.12) !important;
}
.v-data-table__mobile-row {
  height: auto;
  min-height: 48px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 0 !important;
}
.v-data-table__mobile-row__header {
  padding-right: 16px;
  font-weight: bold;
}

.v-data-table__mobile-row__cell {
  text-align: right;
}
.v-data-table__mobile-row__cell .v-list {
  padding: 0;
}
.v-data-table__mobile-row__cell .v-list .v-list-item {
  padding: 0;
}
</style>