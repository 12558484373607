import { createRouter, createWebHashHistory } from 'vue-router'
import { auth } from '@/firebase';
import CoinLogin from '@/views/CoinLogin.vue';
import CoinPortfolio from '@/views/CoinPortfolio.vue';

const routes = [
  { path: '/login', name:'Login', component: CoinLogin },
  {
    path: '/',
    name: 'Home',
    component: CoinPortfolio,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next({ name: 'CoinLogin' });
  } else {
    next();
  }
});

export default router
