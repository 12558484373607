<template>
  <v-dialog v-model="dialogVisible" max-width="400">
    <v-card>
      <v-card-title>Editer une cryptomonnaie</v-card-title>
      <v-card-text>
        <v-list-item
          class="mb-4 pl-0"
          :prepend-avatar="logo"
          :title="name"
          :subtitle="symbol"
        ></v-list-item>
        <v-text-field
          v-model.number="quantity"
          variant="outlined"
          label="Quantité"
          clearable
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey-darken-1" text @click="dialogVisible = !dialogVisible">Annuler</v-btn>
        <v-btn color="green-darken-1" text @click="editCoin">Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { nextTick, ref, watch, reactive } from 'vue';
import { db } from '@/firebase';
import { ref as dbRef, update } from 'firebase/database';

export default {
  name: 'editCoin',
  props: {
    coin: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:value'],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('update:value', newValue);
      },
    },
    quantity: {
      get() {
        return this.coin.quantity;
      },
      set(newValue) {
        this.coin.quantity = newValue;
        //this.$emit('update:coin', newValue);
      },
    },
    logo: {
      get() {
        return this.coin.logo;
      },
      set(newValue) {
        this.coin = newValue;
        //this.$emit('update:coin', newValue);
      },
    },
    name: {
      get() {
        return this.coin.name;
      },
      set(newValue) {
        this.coin = newValue;
        //this.$emit('update:coin', newValue);
      },
    },
    symbol: {
      get() {
        return this.coin.symbol;
      },
      set(newValue) {
        this.coin = newValue;
        //this.$emit('update:coin', newValue);
      },
    },
    key: () => {
      return this.coin.key;
    }
  },
  watch: {
    quantity(newCoin, oldCoin) {
      console.log(newCoin, oldCoin);
    }
  },
  setup(props, { emit }) {
    const selectedCoin = ref(null);
    const searchCoinText = ref('');
    const cryptocurrencyOptions = ref([]);

    async function fetchCryptocurrencies(query) {
      const data = await coingecko.searchCryptocurrency(query);
      if (data) {
        cryptocurrencyOptions.value = data.coins;
      }
    }

    watch(searchCoinText, async (newTxt) => {
      if (newTxt.length % 3 === 1 && newTxt.length > 1) {
        await fetchCryptocurrencies(newTxt)
      }
    });

    function editCoin() {
      let coin = Object.assign({}, props.coin);
      if (coin?.quantity != null) {
        let updates = {};
        let key = coin.key;
        console.log(key);
        updates['/portfolio/' + key + '/quantity'] = coin.quantity;
        update(dbRef(db), updates).then(() => {
          emit('update:value', false);
        });
      }
      
      // if (selectedCoin.value && quantity.value > 0) {
      //   let portfolioDatas = {
      //     id: selectedCoin.value.id,
      //     name: selectedCoin.value.name,
      //     symbol: selectedCoin.value.symbol,
      //     logo: selectedCoin.value.large,
      //     quantity: quantity.value
      //   };
      //   const newPortfolio = push(dbRef(db, 'portfolio'), portfolioDatas);
      //   await set(newPortfolio, portfolioDatas);

      //   // Fermer la boîte de dialogue
      
      

      //   // Réinitialiser les valeurs
      //   selectedCoin.value = null;
      //   quantity.value = null;
      // }
    }

    return {
      selectedCoin,
      searchCoinText,
      cryptocurrencyOptions,
      fetchCryptocurrencies,
      editCoin,
    };
  },
};
</script>
